import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import config from "../../gatsby-config";

const SEO = ({
  title,
  description,
  image,
  pathname,
  keywords,
  canonical,
  ogData,
  jsonLd,
  siteMetadata,
  children,
}) => {
  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    defaultKeywords,
    defaultCanonical,
    defaultOgData,
  } = siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage || "/meta/og/1024X512/home.png"}`,
    url: `${siteUrl}${pathname || ""}`,
    keywords: keywords
      ? keywords
          .split(",")
          .map((keyword) => keyword.trim())
          .join(", ")
      : defaultKeywords,
    canonical: canonical || defaultCanonical || `${pathname || ""}`,
    ogData: ogData || defaultOgData,
  };

  const ogList = seo.ogData || {};

  if (!ogList["og:title"]) ogList["og:title"] = seo.title;
  if (!ogList["og:description"]) ogList["og:description"] = seo.description;
  if (!ogList["og:type"]) ogList["og:type"] = "website";
  if (!ogList["og:url"]) ogList["og:url"] = seo.canonical;
  if (!ogList["og:image"]) {
    ogList["og:image"] = [
      "https://peppercloud.com/meta/og/1200X630/homeNew.png", // Primary image
      "https://peppercloud.com/meta/og/1024X512/home.png", // Fallback option (close to ideal size)
      "https://peppercloud.com/meta/og/1X1/home.png", // Optional, avoid using it as primary
    ];
  }

  const renderJsonLd = () => {
    if (!jsonLd) return null;

    return Array.isArray(jsonLd) ? (
      jsonLd.map((item, index) => (
        <script key={`jsonld-${index}`} type="application/ld+json">
          {JSON.stringify(item)}
        </script>
      ))
    ) : (
      <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
    );
  };

  return (
    <Helmet title={seo.title}>
      <meta content={seo.description} name="description" />
      <meta
        content={seo.keywords || seo.description.split(" ").join(", ")}
        name="keywords"
      />
      {seo.canonical && <link href={seo.canonical} rel="canonical" />}
      <meta content={seo.ogData?.image?.path || seo.image} name="image" />
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-615460128"
      />
      <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-615460128');`}
      </script>
      <script>
        {`function gtag_report_conversion(url) {
          var callback = function () {
            if (typeof url != 'undefined') {
              window.location = url;
            }
          }
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('event', 'conversion', {
            send_to: 'AW-615460128/SjQlCNTTy4MYEKDavKUC',
            event_callback: callback,
          });
          return false;
        }`}
      </script>
      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-P3QXG9X')`}
      </script>
      {Object.keys(ogList).map((property, index) =>
        typeof ogList[property] === "string" ? (
          <meta content={ogList[property]} key={index} property={property} />
        ) : (
          ogList[property].map((each, eachIndex) => (
            <meta
              content={each}
              key={`${index}-${eachIndex}`}
              property={property}
            />
          ))
        )
      )}
      {renderJsonLd()}
      {children}
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  keywords: PropTypes.string,
  canonical: PropTypes.string,
  ogData: PropTypes.object,
  jsonLd: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  siteMetadata: PropTypes.object,
  children: PropTypes.any,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
  siteMetadata: {
    defaultTitle: config.siteMetadata.title,
    defaultDescription: config.siteMetadata.description,
    siteUrl: config.siteMetadata.siteUrl,
    defaultImage: config.siteMetadata.image,
  },
};

export default SEO;
